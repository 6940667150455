/**
 * Template Name: Top Taxi
 * Updated: 17.7.2023.
 * Author: MVSoft Services
 */
import Swiper from "swiper/bundle";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap";

// import styles bundle
import "swiper/css/bundle";

(function () {
    "use strict";

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all);
        if (selectEl) {
            if (all) {
                selectEl.forEach((e) => e.addEventListener(type, listener));
            } else {
                selectEl.addEventListener(type, listener);
            }
        }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener("scroll", listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select("#navbar .scrollto", true);
    const navbarlinksActive = () => {
        let position = window.scrollY + 200;
        navbarlinks.forEach((navbarlink) => {
            if (!navbarlink.hash) return;
            let section = select(navbarlink.hash);
            if (!section) return;
            if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
                navbarlink.classList.add("active");
            } else {
                navbarlink.classList.remove("active");
            }
        });
    };
    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let header = select("#header");
        let offset = header.offsetHeight;

        let elementPos = select(el).offsetTop;
        window.scrollTo({
            top: elementPos - offset,
            behavior: "smooth",
        });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select("#header");
    let selectTopbar = select("#topbar");
    if (selectHeader) {
        const headerScrolled = () => {
            if (window.scrollY > 100) {
                selectHeader.classList.add("header-scrolled");
                if (selectTopbar) {
                    selectTopbar.classList.add("topbar-scrolled");
                }
            } else {
                selectHeader.classList.remove("header-scrolled");
                if (selectTopbar) {
                    selectTopbar.classList.remove("topbar-scrolled");
                }
            }
        };
        window.addEventListener("load", headerScrolled);
        onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    let backtotop = select(".back-to-top");
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add("active");
            } else {
                backtotop.classList.remove("active");
            }
        };
        window.addEventListener("load", toggleBacktotop);
        onscroll(document, toggleBacktotop);
    }

    /**
     * Modal youtube link
     */
    const modal = select(".modal-dialog iframe");
    on("click", ".modal-button", function (e) {
        const iframe = select(".modal-dialog iframe");
        if (iframe.getAttribute("src") == "") {
            iframe.setAttribute("src", "https://www.youtube.com/embed/n6_7z7BfQz8");
        } else {
            iframe.setAttribute("src", "");
        }
    });
    on("click", ".modal-content .btn-close", function (e) {
        const iframe = select(".modal-dialog iframe");
        iframe.setAttribute("src", "");
    });

    /**
     * Mobile nav toggle
     */
    on("click", ".mobile-nav-toggle", function (e) {
        select("#navbar").classList.toggle("navbar-mobile");
        this.classList.toggle("bi-list");
        this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    on(
        "click",
        ".navbar .dropdown > a",
        function (e) {
            if (select("#navbar").classList.contains("navbar-mobile")) {
                e.preventDefault();
                this.nextElementSibling.classList.toggle("dropdown-active");
            }
        },
        true
    );

    on(
        "click",
        "#language-select .hidden_class > div",
        function (e) {
            if (select("#language-select").classList.contains("hidden_class")) {
                e.preventDefault();
                this.nextElementSibling.classList.toggle("visible_class");
            }
        },
        true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on(
        "click",
        ".scrollto",
        function (e) {
            if (select(this.hash)) {
                e.preventDefault();

                let navbar = select("#navbar");
                if (navbar.classList.contains("navbar-mobile")) {
                    navbar.classList.remove("navbar-mobile");
                    let navbarToggle = select(".mobile-nav-toggle");
                    navbarToggle.classList.toggle("bi-list");
                    navbarToggle.classList.toggle("bi-x");
                }
                scrollto(this.hash);
            }
        },
        true
    );

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener("load", () => {
        if (window.location.hash) {
            if (select(window.location.hash)) {
                scrollto(window.location.hash);
            }
        }
    });

    /**
     * Preloader
     */
    let preloader = select("#preloader");
    if (preloader) {
        window.addEventListener("load", () => {
            preloader.remove();
        });
    }

    /**
     * Testimonials slider
     */
    new Swiper(".testimonials-slider", {
        speed: 600,
        loop: true,
        // autoplay: {
        //     delay: 5000,
        //     disableOnInteraction: false,
        // },
        autoplay: false,
        slidesPerView: "auto",
        navigation: {
            nextEl: ".testimonials-swiper-button-next",
            prevEl: ".testimonials-swiper-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },

            1200: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });
})();

// Get references to the div elements
const sClassDiv = document.querySelector(".s-class");
const vClassDiv = document.querySelector(".v-class");
const vehicleCardSClass = document.querySelector("#s-class");
const vehicleCardVClass = document.querySelector("#v-class");

// Add click event listeners
sClassDiv.addEventListener("click", function () {
    if (!vehicleCardSClass.classList.contains("border-class")) {
        // Add 'border-class' to the 'vehicle-card' div
        vehicleCardSClass.classList.add("border-class");

        // If 'vehicleCardVClass' already has 'border-class', remove it
        vehicleCardVClass.classList.remove("border-class");
    }

    // Toggle 'badge-primary' and 'badge-secondary' classes
    sClassDiv.classList.toggle("badge-primary");
    sClassDiv.classList.toggle("badge-secondary");

    // Ensure 'vClassDiv' is set to 'badge-secondary'
    vClassDiv.classList.remove("badge-primary");
    vClassDiv.classList.add("badge-secondary");
});

vClassDiv.addEventListener("click", function () {
    if (!vehicleCardVClass.classList.contains("border-class")) {
        // Add 'border-class' to the 'vehicle-card' div
        vehicleCardVClass.classList.add("border-class");

        // If 'vehicleCardSClass' already has 'border-class', remove it
        vehicleCardSClass.classList.remove("border-class");
    }

    // Toggle 'badge-primary' and 'badge-secondary' classes
    vClassDiv.classList.toggle("badge-primary");
    vClassDiv.classList.toggle("badge-secondary");

    // Ensure 'sClassDiv' is set to 'badge-secondary'
    sClassDiv.classList.remove("badge-primary");
    sClassDiv.classList.add("badge-secondary");
});

if (window.location.search.includes("send=true")) {
    // Wait for the DOM to be ready
    document.addEventListener("DOMContentLoaded", function () {
        const form = document.querySelector("#contact_form");

        // Scroll to the form
        form.scrollIntoView({ behavior: "smooth" });
    });
}
